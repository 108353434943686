/* FONTS */
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Quicksand&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&family=Quicksand&display=swap'); */


/* @media{ (prefers-reduced-motion: no-preference)
    :root {
        scroll-behavior: smooth;
    }
} */



body{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

.align-items-center {
  align-items: center !important;
}


/* ----------- TEXT AND FONT ----------- */

.text-white {
  color: white !important;
  font-family: 'Quicksand', sans-serif;
}

.text-gray {
  color: #999999 !important;
  font-family: 'Quicksand', sans-serif;
}

.text-gray-600 {
  color: #999999;
  font-family: 'Quicksand', sans-serif;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-center {
  text-align: center !important;
}

.text-yellow {
  color: #FBEB7E;
  font-family: 'Quicksand', sans-serif;
}

/* ----------- FLEX - PADDING - MARGIN ----------- */

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-lg-start {
  align-items: flex-start !important;
}
.align-items-lg-end {
  align-items: flex-end !important;
}
.align-items-lg-center {
  align-items: center !important;
}
.align-items-lg-baseline {
  align-items: baseline !important;
}
.align-items-lg-stretch {
  align-items: stretch !important;
}
.align-content-lg-start {
  align-content: flex-start !important;
}
.align-content-lg-end {
  align-content: flex-end !important;
}
.align-content-lg-center {
  align-content: center !important;
}
.align-content-lg-between {
  align-content: space-between !important;
}
.align-content-lg-around {
  align-content: space-around !important;
}
.align-content-lg-stretch {
  align-content: stretch !important;
}
.align-self-lg-auto {
  align-self: auto !important;
}
.align-self-lg-start {
  align-self: flex-start !important;
}
.align-self-lg-end {
  align-self: flex-end !important;
}
.align-self-lg-center {
  align-self: center !important;
}
.align-self-lg-baseline {
  align-self: baseline !important;
}
.align-self-lg-stretch {
  align-self: stretch !important;
}
.order-lg-first {
  order: -1 !important;
}
.order-lg-0 {
  order: 0 !important;
}
.order-lg-1 {
  order: 1 !important;
}
.order-lg-2 {
  order: 2 !important;
}
.order-lg-3 {
  order: 3 !important;
}
.order-lg-4 {
  order: 4 !important;
}
.order-lg-5 {
  order: 5 !important;
}
.order-lg-last {
  order: 6 !important;
}
.m-lg-0 {
  margin: 0 !important;
}
.m-lg-1 {
  margin: 0.25rem !important;
}
.m-lg-2 {
  margin: 0.5rem !important;
}
.m-lg-3 {
  margin: 1rem !important;
}
.m-lg-4 {
  margin: 1.5rem !important;
}
.m-lg-5 {
  margin: 3rem !important;
}
.m-lg-auto {
  margin: auto !important;
}
.mx-lg-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-lg-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-lg-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-lg-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-lg-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-lg-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-lg-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-lg-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-lg-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-lg-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-lg-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-lg-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-lg-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-lg-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-lg-0 {
  margin-top: 0 !important;
}
.mt-lg-1 {
  margin-top: 0.25rem !important;
}
.mt-lg-2 {
  margin-top: 0.5rem !important;
}
.mt-lg-3 {
  margin-top: 1rem !important;
}
.mt-lg-4 {
  margin-top: 1.5rem !important;
}
.mt-lg-5 {
  margin-top: 3rem !important;
}
.mt-lg-auto {
  margin-top: auto !important;
}
.me-lg-0 {
  margin-right: 0 !important;
}
.me-lg-1 {
  margin-right: 0.25rem !important;
}
.me-lg-2 {
  margin-right: 0.5rem !important;
}
.me-lg-3 {
  margin-right: 1rem !important;
}
.me-lg-4 {
  margin-right: 1.5rem !important;
}
.me-lg-5 {
  margin-right: 3rem !important;
}
.me-lg-auto {
  margin-right: auto !important;
}
.mb-lg-0 {
  margin-bottom: 0 !important;
}
.mb-lg-1 {
  margin-bottom: 0.25rem !important;
}
.mb-lg-2 {
  margin-bottom: 0.5rem !important;
}
.mb-lg-3 {
  margin-bottom: 1rem !important;
}
.mb-lg-4 {
  margin-bottom: 1.5rem !important;
}
.mb-lg-5 {
  margin-bottom: 3rem !important;
}
.mb-lg-auto {
  margin-bottom: auto !important;
}
.ms-lg-0 {
  margin-left: 0 !important;
}
.ms-lg-1 {
  margin-left: 0.25rem !important;
}
.ms-lg-2 {
  margin-left: 0.5rem !important;
}
.ms-lg-3 {
  margin-left: 1rem !important;
}
.ms-lg-4 {
  margin-left: 1.5rem !important;
}
.ms-lg-5 {
  margin-left: 3rem !important;
}
.ms-lg-auto {
  margin-left: auto !important;
}
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-lg-0 {
  padding: 0 !important;
}
.p-lg-1 {
  padding: 0.25rem !important;
}
.p-lg-2 {
  padding: 0.5rem !important;
}
.p-lg-3 {
  padding: 1rem !important;
}
.p-lg-4 {
  padding: 1.5rem !important;
}
.p-lg-5 {
  padding: 3rem !important;
}
.px-lg-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-lg-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-lg-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-lg-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-lg-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-lg-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-lg-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-lg-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-lg-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-lg-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-lg-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-lg-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-lg-0 {
  padding-top: 0 !important;
}
.pt-lg-1 {
  padding-top: 0.25rem !important;
}
.pt-lg-2 {
  padding-top: 0.5rem !important;
}
.pt-lg-3 {
  padding-top: 1rem !important;
}
.pt-lg-4 {
  padding-top: 1.5rem !important;
}
.pt-lg-5 {
  padding-top: 3rem !important;
}
.pe-lg-0 {
  padding-right: 0 !important;
}
.pe-lg-1 {
  padding-right: 0.25rem !important;
}
.pe-lg-2 {
  padding-right: 0.5rem !important;
}
.pe-lg-3 {
  padding-right: 1rem !important;
}
.pe-lg-4 {
  padding-right: 1.5rem !important;
}
.pe-lg-5 {
  padding-right: 3rem !important;
}
.pb-lg-0 {
  padding-bottom: 0 !important;
}
.pb-lg-1 {
  padding-bottom: 0.25rem !important;
}
.pb-lg-2 {
  padding-bottom: 0.5rem !important;
}
.pb-lg-3 {
  padding-bottom: 1rem !important;
}
.pb-lg-4 {
  padding-bottom: 1.5rem !important;
}
.pb-lg-5 {
  padding-bottom: 3rem !important;
}
.ps-lg-0 {
  padding-left: 0 !important;
}
.ps-lg-1 {
  padding-left: 0.25rem !important;
}
.ps-lg-2 {
  padding-left: 0.5rem !important;
}
.ps-lg-3 {
  padding-left: 1rem !important;
}
.ps-lg-4 {
  padding-left: 1.5rem !important;
}
.ps-lg-5 {
  padding-left: 3rem !important;
}
.gap-lg-0 {
  gap: 0 !important;
}
.gap-lg-1 {
  gap: 0.25rem !important;
}
.gap-lg-2 {
  gap: 0.5rem !important;
}
.gap-lg-3 {
  gap: 1rem !important;
}
.gap-lg-4 {
  gap: 1.5rem !important;
}
.gap-lg-5 {
  gap: 3rem !important;
}
.text-lg-start {
  text-align: left !important;
}
.text-lg-end {
  text-align: right !important;
}
.text-lg-center {
  text-align: center !important;
}
.col-md-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-md-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}
.col-md-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}
.col-md-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-md-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}
.col-md-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}
.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-md-7 {
  flex: 0 0 auto;
  width: 55%;
}
.col-md-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}
.col-md-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-md-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}
.col-md-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}
.col-md-12 {
  flex: 0 0 auto;
  width: 45%;
}




/* ----------- BUTTON SECTION ----------- */
.btn_group {
  position: relative;
  z-index: 1;
  display: block;
}

.btn.gray {
  background-color: #54595f;
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
}

.btn.gray:before {
  border-color: #54595f;
}

.btn.gray:hover {
  background-color: rgba(84, 89, 95, 0.6);
  color: #ffffff;
}

body.bg-dark.bg-white .btn.gray{
  color: #ffffff;
}



/* ----------- BACKGROUND ----------- */
.bg-white{
    opacity: 1;
    background-color: #FFFFFF;
}

.bg-dark{
    opacity: 1;
    background-color: #343641;
}

.bg-dark-100 {
  background: #1b1b1b;
}

.bg-dark-200 {
  background: #212121;
}

.bg-dark-300 {
  background: #2e2e2e;
}

.bg-transparent {
  opacity: 1;
  background-color: transparent !important;
}


/* ----------- Grid ----------- */
.gutter-20 {
  margin: 0 -1.25rem;
}

.gutter-20 .grid-item {
  padding: 1.25rem;
}


/* #mode_switcher span {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ffffff;
    font-size: 30px;
    background-color: #000000;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: rotateSwitcher 5s infinite linear;
  } */

  /*----------- HEADER ----------- */
.header{
    color: white;
    padding: 1rem;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%; 
    background-color: transparent;
    z-index: 9;
    transition: all 0.5s ease-in-out;
}

.header .header_inner{
    padding: 1.875rem 0rem;
    transition: all 0.5s ease-in-out;
}

.header.sticky {
    position:fixed;
    top: 0;
    left: 0;  
    width: 100%;
    background-color: #000;
    transform: perspective(300px) rotateX(0deg);
    transform-origin: top;
    z-index: 100;
  }

.header.sticky .header_inner {
    padding: 0.625rem 0rem;
    transition: all 0.5s ease-in-out;
  }

.header .top_bar.top-up {
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    transform: perspective(300px) rotateX(-90deg);
    transform-origin: top;
  }

.header .logo{
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
}

.header .logo a {
    display: inline-block;
}

.header .logo a img{
    width: 100%;
    max-width: 230px;
    transition: all 0.5s ease-in-out;
}

.header .logo-item{
    max-width: 75px;
    margin-left: 2.3rem;

}

.header .mainnav ul.main_menu {
    padding: 0rem;
    margin: 0rem;
  }

.header .mainnav ul.main_menu li {
    position: relative;
    border: 0;
    list-style: none;
    line-height: 1;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  .header .mainnav ul.main_menu li a {
    border: 0;
    list-style: none;
    display: inline-block;
    position: relative;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    padding: 1.875rem 0rem;
    letter-spacing: 1.2px;
  }

.header .mainnav ul.main_menu li:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0px, 0px, 0px);
  }
  
  .header .mainnav ul.main_menu li.current > a, .header .mainnav ul.main_menu li.active > a, .header .mainnav ul.main_menu li:hover > a {
    color: #FBEB7E;
  }

  .header .mainnav ul.main_menu li ul li a:hover {
    color: #FBEB7E;
  }
  
  .header .mainnav ul.main_menu li ul li:last-child > a {
    border-bottom: none;
  }
  
  .header .mainnav ul.main_menu li ul li.menu-item-has-children > a:after {
    content: "\F285";
    font-family: 'bootstrap-icons';
    /* speak: none; */
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  } 
  
  .header .mainnav ul.main_menu > li:not(:last-child) > a {
    padding-right: calc(1.5625rem + 3.21429vw);
  } 
  
  @media (min-width: 1400px) {
    .header .mainnav ul.main_menu > li:not(:last-child) > a {
      padding-right: 0rem;
    }
  }
  
  .header .mainnav ul.main_menu > li.menu-item-has-children > a::after {
    content: "\F282";
    color: #ffffff;
    font-family: 'bootstrap-icons';
    transition: all 0.5s ease-in-out;
    display: inline-block;
    margin-left: 5px;
    font-size: 10px;
    line-height: normal;
  }
  
  .header .mainnav ul.main_menu > li.menu-item-has-children.current a::after, .header .mainnav ul.main_menu > li.menu-item-has-children.active a::after, .header .mainnav ul.main_menu > li.menu-item-has-children:hover a::after {
    color: #FBEB7E;
  }

  .mr_menu_toggle {
    background: transparent;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    /* padding: 0rem; */
    color: white;
    font-size: 1.5rem;
    transition: all 0.5s ease-in-out;
    flex-shrink: 0;
    border: 0;
  }
  
  .mr_menu_toggle:focus {
    outline: none;
    
  }
  
  .mr_menu_toggle:hover {
    color: #FBEB7E;
  }


body.bg-dark.bg-white .header .logo a.light_logo, body.bg-white .header .logo a.light_logo {
    opacity: 0;
    visibility: hidden;
  }

  body.bg-dark.bg-white .header .mainnav ul.main_menu li ul, body.bg-white .header .mainnav ul.main_menu li ul {
    background: #ffffff;
  }
  
  body.bg-dark.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children > a::after, body.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children > a::after {
    color: #000000;
  }
  
  body.bg-dark.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children.current a::after, body.bg-dark.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children.active a::after, body.bg-dark.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children:hover a::after, body.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children.current a::after, body.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children.active a::after, body.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children:hover a::after {
    color: #FBEB7E;
  }
  
  
  body.bg-dark.bg-white .header .ma5menu__toggle:hover, body.bg-white .header .ma5menu__toggle:hover {
    color: #FBEB7E;
  }
  
  body.bg-dark.bg-white .theme_slider_2 .swiper-slide .slider .slide_content .bg-dark-100, body.bg-white .theme_slider_2 .swiper-slide .slider .slide_content .bg-dark-100 {
    background-color: #ffffff;
  }
  
  body.bg-dark.bg-white .theme_slider_2 .slide_content_inner h4 a.text-white:hover, body.bg-dark.bg-white .theme_slider_2 .slide_content_inner .h4 a.text-white:hover, body.bg-white .theme_slider_2 .slide_content_inner h4 a.text-white:hover, body.bg-white .theme_slider_2 .slide_content_inner .h4 a.text-white:hover {
    color: #FBEB7E !important;
  }

/* .header .mainnav ul.main_menu img.hide-initial,
.header .mainnav ul.main_menu span.hide-initial {
    visibility: hidden;
    opacity: 0; 
    transition: opacity 0.5s ease-in-out;
} */


  /* 
  *******  ESCONDER Y APARECER LAS INICIALES DE  5 I N C O  ******** */
.header .mainnav ul.main_menu li.menu-item img.hide-initial {
  /* visibility: hidden; */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.header .mainnav ul.main_menu li.menu-item span.hide-initial {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
}

.scroll-to-top:hover {
  background-color: #63636182;
}


  /* ----------- FOOTER ----------- */
  .footer{
    position: relative;
    padding-top: 0;
    /* padding-bottom: calc(1.5625rem + 3.21429vw); */
  }

  .footer.pd-top {
    padding-top: calc(1.5625rem + 3.21429vw);
  }

  .footer .footer_inner {
    position: relative;
    z-index: 1;
    padding: calc(1.6563rem + 4.17909vw) 0;
  }
  
  .footer .footer_inner > div {
    position: relative;
    z-index: 2;
  }

  .footer .footer_inner .communication .info_body h6, .footer .footer_inner .communication .info_body .h6 {
    color: #FBEB7E;
    font-weight: 400;
    line-height: 2.125rem;
  }
  
  .footer .footer_inner .communication .info_body h5, .footer .footer_inner .communication .info_body .h5 {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.25rem;
    word-break: break-all;
  }

  .footer .footer_inner .communication .info_body h5 a, .footer .footer_inner .communication .info_body .h5 .a {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.25rem;
    word-break: break-all;
    text-decoration: none;
  }
  
  .footer .footer_inner .communication .info_body:not(:last-child) {
    margin-bottom: 1.5625rem;
  }

  /* .footer .footer_inner .section-header h2, .footer .footer_inner .section-header .h2 {
    font-size: calc(0.3rem + 2vw);
    line-height: calc(1.65rem + 4.11429vw);
    writing-mode:sideways-rl;
    color: #4c4735c1;
    margin: 0px 0px 0px -47px;
    transform: rotate(180deg);
  } */


  .footer .footer_inner .communication {
    margin: 1.875rem 0rem;
    font-family: 'Quicksand', sans-serif;
  }

  .footer .footer_inner .footer_elements.justify-content-end {
    justify-content: flex-start !important;
  }

  .footer .footer_inner .footer_social {
    text-align: center;
    margin-top: 1.25rem;
    margin-bottom: 1.875rem;
  }

  .footer .footer_inner .footer_social .social_list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0px;
    margin-bottom: 0px;
    cursor: pointer;
  }
  
  .footer .footer_inner .footer_social .social_list li {
    list-style: none;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
  }
  
  .footer .footer_inner .footer_social .social_list li:not(:last-child) {
    margin-right: 10px;
  }

  .footer .footer_inner .footer_social .social_list li a {
    position: relative;
    overflow: hidden;
    font-size: 1.7rem;
    text-align: center;
    color: #ffffff;
    background: #2e2e2e;
    display: block;
    outline: none;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 100%;
  }
  
  .footer .footer_inner .footer_social .social_list li a:hover {
    color: #FBEB7E;
  }
  

  .footer .footer_inner .copyright p {
    font-size: 1rem;
    color: #b2b2b2;
    font-family: 'Quicksand', sans-serif;
  }

  body.bg-dark.bg-white .footer.bg-dark-100, body.bg-dark.bg-white .footer.bg-dark-200, body.bg-white .footer.bg-dark-100, body.bg-white .footer.bg-dark-200 {
    background: #ffffff;
  }

  body.bg-dark.bg-white .project-details .post-footer, body.bg-white .project-details .post-footer {
    border-top-color: #e6e6e6;
  }

  .info_body_elements{
    display: flex;
    align-items: baseline;
  }

  .info_body_elements i {
    margin-right: 7px;
    color: #FBEB7E;
  }



/*----------- THEME SLIDER ----------- */

.slider{
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_images{
  /* height: 10%; */
  width: -webkit-fill-available;
}

.theme_slider {
  position: relative;
  padding: calc(1rem + 1vw) 0 0 0;
}

.theme_slider .swiper {
  padding-bottom: calc(1.5rem + 2.57143vw);
}


.swiper-navigation {
  --button-width: 44px;
  --button-height: 44px;
  position: absolute;
  right: 0px;
  bottom: 0;
  width: var(--button-width);
  height: calc(var(--button-height) * 2);
  overflow: hidden;
  z-index: 9;
  transition: all 1s ease-in-out;
}

.swiper-navigation .swiper-button-next, .swiper-navigation .swiper-button-prev {
  font-size: 1.25rem;
  color: #000000;
  background: #ffffff;
  position: absolute;
  top: 100%;
  width: var(--button-width);
  height: var(--button-height);
  margin-top: 0px;
  transition: all 0.5s ease-in-out;
  border-radius: 50%;
}

.swiper-navigation .swiper-button-next:hover, .swiper-navigation .swiper-button-prev:hover {
  background: #63636182;
  color: #ffffff;
}

.swiper-navigation .swiper-button-prev, .swiper-navigation .swiper-rtl .swiper-button-next {
  left: auto;
  right: 0;
}

.swiper-navigation .swiper-button-next, .swiper-navigation .swiper-rtl .swiper-button-prev {
  right: 0;
  left: auto;
  margin-top: 1px;
}

.swiper-navigation .swiper-button-next.swiper-button-disabled, .swiper-navigation .swiper-button-prev.swiper-button-disabled {
  opacity: 1;
  background: rgba(255, 255, 255, 0.65);
}

.swiper-navigation .swiper-button-next:after, .swiper-navigation .swiper-button-prev:after {
  font-size: 20px;
  display: none;
}

.swiper:hover .swiper-navigation .swiper-button-prev {
  top: 0;
}

.swiper:hover .swiper-navigation .swiper-button-next {
  top: var(--button-height);
}


/*----------- ABOUT ----------- */  

.about{
  padding-top: 8rem;
  padding-bottom: 8rem;
}

/* .about_image {
  position: relative;
}

.about_image img:last-child {
  position: relative;
  left: 20px;
  bottom: 20px;
  height: 350px;
  padding: 1rem;
  margin: 2rem;
} */

/* .about_image img.abt_img_2 {
  position: absolute;
  right: 0;
  left: calc(1.625rem + 3.85714vw);
  bottom: -100px;
}

.about_image img.line_frame_1 {
  position: absolute;
  left: 30px;
  bottom: -80px;
  z-index: -1;
}

.about_image img.line_frame_2 {
  position: absolute;
  left: 350px;
  right: 20px;
  top: 40px;
  z-index: -1;
}


 @media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  } 

  .about_image img:last-child {
    position: absolute;
    left: 20px;
    bottom: 695px;
  }

  .about_image{
    position: relative;
  }
  
  .mb-clearfix{
    padding: 50px;
  }
} */

 /* @media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .about_image img.line_frame_2 {
    position: absolute;
    left: 350px;
    right: 20px;
    top: 40px;
    z-index: -1;
  }
} 

 @media (min-width: 1400px) {
  .about.style_two {
    padding-bottom: 11.875rem;
  }
}


 @media (min-width: 1400px) {
  .about_image img.abt_img_2 {
    left: 5rem;
  }
}  */


.about_text_inner {
  padding-left: 2.8125rem;
}

.about_text {
  margin-bottom: 35px;
  font-family: 'Quicksand', sans-serif;
}

.about_text h2, .about_text .h2 {
  font-size: calc(1.425rem + 1.8vw);
  line-height: 3.6875rem;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.about-paragraph{
  color: white;
}

@media (min-width: 1400px) {
  .about_text h2, .about_text .h2 {
    font-size: 3rem;
  }
}


/*----------- FUN FACTS ----------- */  
.funfacts_inner {
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-flow: row wrap;
  font-family: 'Quicksand', sans-serif;
}

.funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
  color: #ffffff;
  font-size: calc(1.525rem + 2.82857vw);
  font-weight: 600;
  line-height: calc(1.525rem + 2.82857vw);
  margin-bottom: 0;
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
    font-size: 4rem;
  }
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
    line-height: 4rem;
  }
}

.funfacts_inner .funfact .funfact_content span {
  color: #ffffff;
  font-size: calc(1.525rem + 2.82857vw);
  font-weight: 600;
  line-height: calc(1.525rem + 2.82857vw);
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content span {
    font-size: 4rem;
  }
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content span {
    line-height: 4rem;
  }
}

.funfacts_inner .funfact .funfact_content p {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: calc(1.28125rem + 0.32143vw);
  margin-top: calc(1.28125rem + 0.32143vw);
  color: #ffffff;
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content p {
    line-height: 1.5625rem;
  }
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content p {
    margin-top: 1.5625rem;
  }
}

/* .funfacts_inner {
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-flow: row wrap;
} */

.funfacts_inner .funfact2 .fun_img {
  width: 60px;
  height: 60px;
  line-height: 4.125rem;
  border: 0.0625rem dashed #ffffff;
  border-radius: 100%;
  flex-shrink: 0;
  margin-right: 1.25rem;
}

.funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
  font-size: calc(1.275rem + 0.25714vw);
  font-weight: 600;
  line-height: calc(1.325rem + 0.77143vw);
  color: #ffffff;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
    font-size: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
    line-height: 2rem;
  }
}

.funfacts_inner .funfact2 .funfact_content span {
  font-size: calc(1.275rem + 0.25714vw);
  font-weight: 600;
  line-height: calc(1.325rem + 0.77143vw);
  color: #ffffff;
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content span {
    font-size: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content span {
    line-height: 2rem;
  }
}

.funfacts_inner .funfact2 .funfact_content p {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: calc(1.3rem + 0.51429vw);
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0rem;
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content p {
    line-height: 1.75rem;
  }
}

/* .funfacts_inner .funfact2 .fun_img {
  width: 4.375rem;
  height: 4.375rem;
  line-height: 4.125rem;
  border: 0.0625rem dashed #ffffff;
  border-radius: 100%;
  flex-shrink: 0;
  margin-right: 1.25rem;
} */

.funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
  font-size: calc(1.275rem + 0.25714vw);
  font-weight: 600;
  line-height: calc(1.325rem + 0.77143vw);
  color: #ffffff;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
    font-size: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
    line-height: 2rem;
  }
}

.funfacts_inner .funfact2 .funfact_content span {
  font-size: calc(1.275rem + 0.25714vw);
  font-weight: 600;
  line-height: calc(1.325rem + 0.77143vw);
  color: #ffffff;
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content span {
    font-size: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content span {
    line-height: 2rem;
  }
}

.funfacts_inner .funfact2 .funfact_content p {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: calc(1.3rem + 0.51429vw);
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0rem;
}

@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content p {
    line-height: 1.75rem;
  }
}

#funfacts{
  background-color: #2e2e2e;
  padding: 6rem;
}



/*----------- MEMBERS ----------- */  
.projects {
  z-index: 1;
  padding-top: 250px;
}

section.projects.packery.bg-dark-100 {
  padding: 6rem;
}

/* Para manejar el número de miembros por línea*/
/* .swiper_gallery_2{
    display: grid;
    grid-template-columns: repeat(6, 1fr); 
    gap: 20px;
} */

.swiper_gallery_2 .centered-row {
  grid-column: 2 / span 5; 
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px;
  justify-content: center; 
  align-items: center;
}

/* EL SIGUIENTE ES UN INTENTO CON FLEX, PARA QUE LA SEGUNDA FILA TENGA LAS IMÁGENES CENTRADAS - PENDIENTE DE RESOLVER */
/* .swiper_gallery_2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.swiper_gallery_2 .centered-row {
  width: 100%; 
  display: flex;
  justify-content: center;
  gap: 20px; 
} */


.swiper_gallery_2 .swiper-button-next:after, .swiper_gallery_2 .swiper-button-prev:after {
  content: none;
}

h2.text-white.text-uppercase {
  margin-bottom: 3rem;
}

.grid-item {
  overflow: hidden;
  position: relative;
  transition: none;
  display: flex;
  
}

.mt-lg-minus .gutter-90:nth-child(1) .grid-item, .mt-lg-minus .gutter-90:nth-child(2) .grid-item {
  padding-top: 0;
}

.grid-item .thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.effect-tilt .grid-item .thumb {
  transform-style: preserve-3d;
}

.running_projects .grid-item .thumb {
  overflow: visible;
}

.grid-item .works-info {
  margin-top: 1.25rem;
}

.packery .grid-item .works-info {
  position: absolute;
  right: 0;
  top: auto;
  bottom: 0;
  padding: calc(1.34375rem + 0.96429vw);
  margin-top: 0rem;
  max-width: 300px;
  background-color: #1b1b1b;
  transform: perspective(250px) rotateY(-90deg);
  transform-origin: right;
  transition: all 0.5s ease-in-out;
}

/* @media (min-width: 1400px) {
  .packery .grid-item .works-info {
    padding: 2.1875rem;
  }
} */

.packery .grid-item:hover .works-info {
  transform: perspective(250px) rotateY(0deg);
}

.project-grid-list .grid-item .works-info {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%) perspective(250px) rotateY(-90deg);
}

.project-grid-list .grid-item:nth-child(even) .works-info {
  left: 0;
  right: auto;
  transform: translateY(-50%) perspective(250px) rotateY(90deg);
  transform-origin: left;
}

.project-grid-list .grid-item:nth-child(even):hover .works-info {
  transform: translateY(-50%) perspective(250px) rotateY(0deg);
}

body.bg-dark.bg-white .packery .grid-item .works-info, body.bg-white .packery .grid-item .works-info {
  background-color: #f8f8f8;
}

body.bg-dark.bg-white .packery .grid-item .works-info .label-text h6 a, body.bg-dark.bg-white .packery .grid-item .works-info .label-text .h6 a, body.bg-white .packery .grid-item .works-info .label-text h6 a, body.bg-white .packery .grid-item .works-info .label-text .h6 a {
  color: #54595f;
}

.grid-item .label-text h5 a{
  position: relative;
  transition-delay: 0.6s;
  margin-bottom: 0.625rem;
  color: white !important;
  font-family: 'Quicksand', sans-serif;
  text-decoration-line: none;
  font-size: 1.3rem;
  font-weight: 650;
}

.grid-item .label-text h5 a:hover{
  color: #FBEB7E;
}

.grid-item .label-text p {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: #999999;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}


.grid-item .label-text h6, .grid-item .label-text .h6 {
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}

.grid-item .label-text h6 a, .grid-item .label-text .h6 a {
  color: #999999;
}

.grid-item .label-text h6 a:hover, .grid-item .label-text .h6 a:hover {
  color: #ffffff;
}

.grid-item .label-text .popup {
  font-size: 40px;
  color: #fff;
  display: inline-block;
  margin-top: 20px;
}


.packery .grid-item .label-text h6, .packery .grid-item .label-text .h5 {
  letter-spacing: 0.1rem;
  color: white;
  font-family: 'Quicksand', sans-serif;
}

.packery .grid-item .label-text h6 a, .packery .grid-item .label-text .h5 a {
  color: white;
  font-family: 'Quicksand', sans-serif;
}

.packery .grid-item .label-text h5, .packery .grid-item .label-text .h5 {
  margin-top: calc(0.5rem + 0.5vw);
  /* margin-bottom: 1.25rem; */

}

/* @media (min-width: 1400px) {
  .packery .grid-item .label-text h5, .packery .grid-item .label-text .h5 {
    margin-top: 3.125rem;
  }
} */

.packery .grid-item .label-text .details_link {
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #FBEB7E;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}

.packery .grid-item .label-text .details_link a {
  color: #FBEB7E;
}

/* @media screen and (max-width: 500px) {
  .grid-item .label-text h5, .grid-item .label-text .h5, .packery .grid-item .label-text h5, .packery .grid-item .label-text .h5 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
} */

.details_link {
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #FBEB7E;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}

.details_link a {
  color: #FBEB7E;
  display: flex;
  align-items: center;
}

.details_link a .link_text {
  margin-right: 1.875rem;
}



/*----------- NEXT SHOW ----------- */  
/* 
.show_image img:last-child {
  position: relative;
  left: 20px;
  bottom: 20px;
  height: 450px;
} */


.details_link_nextshow {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #FBEB7E;
  margin-bottom: 0px;
  margin-top: 3rem;
  transition-delay: 0.9s;
  text-decoration: none;
}

.details_link_nextshow a {
  color: white;
}

.details_link_nextshow :hover {
  color: #FBEB7E;
}


/*----------- NEWS ----------- */  
.blog{
  padding: 5rem;

}

.blog_post {
  position: relative;
  overflow: hidden;
  height: 488px;
  transition: all 0.5s ease-in-out;
  margin-bottom: 1.875rem;
}

.blog_post .blog_content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px 30px 40px 45px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}

.blog_post .blog_content::before {
  content: '';
  background: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.blog_post .blog_content .post-count {
  font-size: 0.125rem;
  line-height: 0.5rem;
  font-weight: 700;
  transition: all 0.5s ease;
  transform: translateY(10px);
  opacity: 0;
  color: rgba(255, 255, 255, 0.3);
}

.blog_post .blog_content h5, .blog_post .blog_content .h5 {
  margin-bottom: 1.5625rem;
}

.blog_post .blog_content h5 > a, .blog_post .blog_content .h5 > a {
  font-size: 1.25rem;
  line-height: 1.625rem;
}

.blog_post .blog_content p {
  font-size: 1rem;
  margin-bottom: 25px;
  color: #999999;
  transition: all 0.5s ease;
}

.blog_post .blog_content > a {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 1.2px;
  color: #b2b2b2;
  position: relative;
  transition: all 0.5s ease;
}

.blog_post .blog_content > a:after {
  position: absolute;
  /* content: url("../../../build/images/icon_box/i1.svg"); */
  transform: translateX(20px);
  transition: all 0.5s ease;
}

.blog_post .blog_content > a:hover::after {
  transform: translateX(-20px);
}

.blog_post .blog_content .meta {
  margin-top: 10px;
  margin-bottom: 10px;
}

.blog_post .blog_content .meta time {
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 600;
}

.blog_post img {
  width: 120%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.blog_post:hover .blog_content::before {
  background: rgba(0, 0, 0, 0.75);
}

.blog_post:hover .blog_content .post-count {
  font-size: calc(1.575rem + 3.34286vw);
  line-height: 4.875rem;
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 1400px) {
  .blog_post:hover .blog_content .post-count {
    font-size: 4.5rem;
  }
}

.blog_post:hover .blog_content p {
  color: #ffffff;
}

.blog_post:hover .blog_content > a {
  color: #a6a182;
}

.blog_post:hover img {
  opacity: 1;
}

body.bg-dark.bg-white .icon_box.type-2:hover p,
body.bg-dark.bg-white .blog .blog_post:hover .blog_content .text-white, body.bg-white .icon_box.type-2:hover p,
body.bg-white .blog .blog_post:hover .blog_content .text-white {
  color: #ffffff !important;
}

body.bg-dark.bg-white .blog .blog_post .blog_content .meta .text-olive, body.bg-white .blog .blog_post .blog_content .meta .text-olive {
  color: #54595f;
}

body.bg-dark.bg-white .blog .blog_post .blog_content p, body.bg-white .blog .blog_post .blog_content p {
  color: #000000;
}

body.bg-dark.bg-white .blog .blog_post:hover .blog_content .meta .text-olive, body.bg-white .blog .blog_post:hover .blog_content .meta .text-olive {
  color: #FBEB7E;
}

body.bg-dark.bg-white .blog .blog_post:hover .blog_content p, body.bg-white .blog .blog_post:hover .blog_content p {
  color: #ffffff;
}


/*----------- FORM ----------- */  
.contact_us{
  padding: 5rem;
  font-family: 'Quicksand', sans-serif;
}

.contact_us > div {
  position: relative;
  z-index: 2;
}

body.bg-dark.bg-white .contact_us.bg-dark-200, body.bg-white .contact_us.bg-dark-200 {
  background: #ffffff;
}

.home_contact input {
  margin-bottom: 1.5625rem;
  transition: all 0.5s ease;
}

.home_contact input:last-child {
  margin-bottom: 0;
}

.home_contact .btn_group {
  margin-top: 1.5625rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e0e1e2;
  background-color: black;
  background-clip: padding-box;
  border: 1px solid #54595F;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #999999;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f8f8f8;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;

  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #ececec;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}

/* DESDE ACA */

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

/*----------- MISSION CONTAINER ----------- */  
.mission {
  padding-top: 280px;
}

.mission_top_part {
  margin-bottom: 4.375rem;
}

.mission_top_part .section-header {
  margin-bottom: 60px;
  padding: 0 calc(1.3125rem + 0.64286vw);
}

@media (min-width: 1400px) {
  .mission_top_part .section-header {
    padding: 0 1.875rem;
  }
}


.mission_top_part .section-header p {
  font-size: calc(1.3125rem + 0.64286vw);
  line-height: calc(1.4375rem + 1.92857vw);
  margin-top: calc(1.3125rem + 0.64286vw);
  letter-spacing: -1px;
  font-weight: 300;
}

@media (min-width: 1400px) {
  .mission_top_part .section-header p {
    font-size: 1.8rem;
  }
}

@media (min-width: 1400px) {
  .mission_top_part .section-header p {
    line-height: 3.125rem;
  }
}

@media (min-width: 1400px) {
  .mission_top_part .section-header p {
    margin-top: 1.875rem;
  }
}

.has_line{
  display: flex;
  justify-content: center;
}

.mission_top_part .has_line::before {
  bottom: calc(100% + 50px);
}

.mission_top_part .section-header h6{
  font-family: 'Quicksand', sans-serif;
  color: white;
  font-weight: 600;
  font-size: 2rem !important; 


}

body.bg-dark.bg-white .mission_top_part .section-header .text-gray-600, body.bg-white .mission_top_part .section-header .text-gray-600 {
  color: #212121;
}

.mission_bottom_part .section-header .border-line {
  position: relative;
  text-align: right;
}

.mission_bottom_part .section-header .border-line::after {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 124px;
  height: 2px;
  bottom: -20px;
  right: 0px;
}

.mission_bottom_part .mission_content p {
  margin-bottom: 1.25rem;
  color:#999999;
  font-family: 'Quicksand', sans-serif;
}

.mission_bottom_part .mission_content p:last-child {
  margin-bottom: 0;
}

.wrapper {
  position: relative;

}

.about-us-img{
  margin-bottom: 5rem;
}

.video_post {
  display: inline-block;
  width: 100%;
}

.video_post .ytube_video {
  position: relative;
  overflow: hidden;
}

.video_post .ytube_video #ytvideo {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.video_post .ytube_video .ytplay_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 210px;
  height: 210px;
  background: rgba(255, 255, 255, 0.25);
  text-align: center;
  font-size: 5rem;
  color: #ffffff;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_post .ytube_video.play #ytvideo {
  opacity: 1;
}

.video_post .ytube_video.play .post_content {
  opacity: 0;
  visibility: hidden;
}

iframe, object, embed, iframe, object, iframe, video {
  width: 100%;
  height: 100%;
  min-height: calc(5.5rem + 43.71429vw);
  border: 0px solid #ffb7aa;
}

@media (min-width: 1400px) {
  iframe, object, embed, iframe, object, iframe, video {
    min-height: 43.75rem;
  }
}


/*----------- SHOWS ----------- */  
.thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

img.item_image {
  width: 100%;
  /* transition: all 0.5s ease-in-out; */
}

img.item_image {
  width: calc(100% - 150px);
}

.thumb:hover img.item_image {
  /* transform: scale(1.1); */
  /* filter: brightness(0.6) contrast(1.1) saturate(1.2) blur(3px); */
  transform: scale(1.1);
  filter: brightness(0.8) contrast(1.1) saturate(1.2);
}

img.item_image {
  width: auto;
}

.projects {
  z-index: 1;
  padding-top: 250px;
}

.projects .section-header {
  max-width: calc(6.75rem + 56.57143vw);
  margin: 0rem auto 3.75rem;
}

@media (min-width: 1400px) {
  .projects .section-header {
    max-width: 56.25rem;
  }
}

.projects .section-header .section-desc {
  margin-top: 35px;
}

/* .top-margin{
  margin-top: -300px;
}

@media screen and (max-width: 576px) {
  .top-margin{
    margin-top: 0;
  }
} */

.effect-tilt .grid-item .thumb {
  transform-style: preserve-3d;
}

.effect-tilt .grid-item .label-text {
  top: 0%;
  left: 0%;
  transform: none;
}

@media (min-width: 1400px) {
  .gutter-80 .grid-item {
    padding: 5rem;
  }
}

.gutter-90 {
  margin: 0 calc(-4.375rem - 4.5vw);
}

@media (min-width: 1400px) {
  .gutter-90 {
    margin: 0 -5.625rem;
  }
}

.gutter-90 .grid-item {
  padding: calc(1.6875rem + 4.5vw);
}

@media (min-width: 1400px) {
  .gutter-90 .grid-item {
    padding: 4rem;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.clearfix {
  clear: both;
  padding-top: 2rem;
  padding-bottom: 200px;
}


@media (max-width: 575.98px) {
  .mb-clearfix{
    padding: 30px;
  }
}

@media (min-width: 992px) {
  .col-lg-6 {
      flex: 0 0 auto;
      width: 33%;
  }
}

/*----------- SHOW CONTAINER----------- */  
.project-details{
  padding-top: 260px;
}

.project-details .post-image {
  margin-bottom: 2.5rem;
}

.post-image img{
  width: 100%;
} 

#show_posters {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#show_poster{
  width: 45%;
  margin: 1rem;
}


/*----------- RELATED SHOW ----------- */  
.grid-item img#relatedshow_img {
  width: 250%;
}

#related-posts{
  padding-bottom: 200px;
}


/*----------- SIDE SHOW DETAILS----------- */  

.sidebar {
  position: sticky;
  top: 100px;
  background-color: #262626;
  padding: 30px;
}

body.bg-dark.bg-white .sidebar, body.bg-white .sidebar {
  background-color: #f8f8f8;
}

body.bg-dark.bg-white .sidebar a, body.bg-white .sidebar a {
  color: #000000;
}

body.bg-dark.bg-white .sidebar a:hover, body.bg-white .sidebar a:hover {
  color: #a6a182;
}

body.bg-dark.bg-white .sidebar .widget ul li, body.bg-dark.bg-white .sidebar .widget ol li, body.bg-white .sidebar .widget ul li, body.bg-white .sidebar .widget ol li {
  border-bottom-color: #e6e6e6;
}

body.bg-dark.bg-white .sidebar .widget_search .wp-block-search .wp-block-search__input, body.bg-white .sidebar .widget_search .wp-block-search .wp-block-search__input {
  border-color: #e6e6e6;
}

#sidebar a.active {
  color: #a6a182;
}

.project-information {
  color: #ffffff;
  font-size: 1rem;
}

.project-information .widget-title {
  font-size: 1.25rem;
  margin-bottom: 1.125rem;
}

.project-information ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.project-information ul li {
  padding: 0.3125rem 0rem;
  display: flex;
}

.project-information ul li:first-child {
  padding-top: 0rem;
}

.project-information ul li:last-child {
  background-image: none;
}

.project-information ul li .data {
  flex-shrink: 0;
  margin-right: 0.75rem;
  font-weight: 600;
}

.project-information ul li .value {
  color: #b2b2b2;
  font-weight: 400;
}

.project-information ul li a {
  color: #b2b2b2;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.project-information .project-share {
  margin-top: 2.5rem;
}

.project-information .project-share ul {
  margin: 0px;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.project-information .project-share ul li {
  width: auto;
  background-image: none;
  margin-right: 10px;
  padding: 0px;
  font-weight: 600;
}

.project-information .project-share ul li a {
  display: block;
  font-size: 0.875rem;
  text-align: center;
  background: #545454;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-information .project-share ul li:last-child {
  margin-right: 0px;
}

body.bg-dark.bg-white .project-information, body.bg-white .project-information {
  color: #000000;
}

body.bg-dark.bg-white .project-information ul li .value, body.bg-white .project-information ul li .value {
  color: #1b1b1b;
}

body.bg-dark.bg-white .project-information .project-share ul li a, body.bg-white .project-information .project-share ul li a {
  background-color: #e6e6e6;
  color: #54595f;
}

.project-details h6.widget-title, .project-details .widget-title.h6 {
  color: #ffffff;
}

.widget h2, .widget .h2,
.widget .widget-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 15px;
}


.widget-title {
  position: relative;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: calc(1.3125rem + 0.64286vw);
  padding-bottom: 10px;
  overflow: hidden;
}

@media (min-width: 1400px) {
  .widget-title {
    margin-bottom: 1.875rem;
  }
}

body.bg-dark.bg-white .project-details h6.widget-title, body.bg-dark.bg-white .project-details .widget-title.h6, body.bg-dark.bg-white .project-details .widget-title.h6,
body.bg-dark.bg-white .comments-area .comments-title, body.bg-dark.bg-white .comment-respond .comment-reply-title,
body.bg-dark.bg-white .comments-area ul.comment-list .commenter-block .comment-content .comment-author-name, body.bg-white .project-details h6.widget-title, body.bg-white .project-details .widget-title.h6, body.bg-white .project-details .widget-title.h6,
body.bg-white .comments-area .comments-title, body.bg-white .comment-respond .comment-reply-title,
body.bg-white .comments-area ul.comment-list .commenter-block .comment-content .comment-author-name {
  color: #000000;
}

body.bg-dark.bg-white .widget h2, body.bg-dark.bg-white .widget .h2, body.bg-dark.bg-white .widget .h2, body.bg-dark.bg-white .widget .widget-title, body.bg-white .widget h2, body.bg-white .widget .h2, body.bg-white .widget .h2, body.bg-white .widget .widget-title {
  color: #000000;
}

body.bg-dark.bg-white .related-posts .widget-title, body.bg-white .related-posts .widget-title {
  color: #000000;
}

body.bg-dark.bg-white .related-posts .grid-item .label-text h5, body.bg-dark.bg-white .related-posts .grid-item .label-text .h5,
body.bg-dark.bg-white .related-posts .grid-item .label-text h5 a,
body.bg-dark.bg-white .related-posts .grid-item .label-text .h5 a, body.bg-white .related-posts .grid-item .label-text h5, body.bg-white .related-posts .grid-item .label-text .h5,
body.bg-white .related-posts .grid-item .label-text h5 a,
body.bg-white .related-posts .grid-item .label-text .h5 a {
  color: #000000;
}

.related-posts h6{
  font-size: 2rem;
  font-weight: 500;
}

/* -------- grid ---------- */
.grid-1 .grid-item, .grid-1 .grid-sizer {
  width: 100%;
}

.grid-2 .grid-item, .grid-2 .grid-sizer {
  width: 50%;
}

.grid-3 .grid-item, .grid-3 .grid-sizer {
  width: 33.33%;
}

.grid-4 .grid-item, .grid-4 .grid-sizer {
  width: 25%;
}

.grid-5 .grid-item, .grid-5 .grid-sizer {
  width: 20%;
}

.grid-2 .grid-sizer.small {
  width: 1%;
}

.grid-3 .grid-sizer.small {
  width: 1%;
}

.grid-4 .grid-sizer.small {
  width: 1%;
}

@media screen and (max-width: 991px) {
  .grid-4 .grid-item, .grid-4 .grid-sizer,
  .grid-5 .grid-item, .grid-5 .grid-sizer {
    width: 33.33%;
  }
  .grid-item.width-20,
  .grid-item.width-25,
  .grid-item.width-30,
  .grid-item.width-35,
  .grid-item.width-40,
  .grid-item.width-50,
  .grid-item.width-60,
  .grid-item.width-66,
  .grid-item.width-70,
  .grid-item.width-75,
  .grid-item.width-80 {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .grid-3 .grid-item, .grid-3 .grid-sizer,
  .grid-4 .grid-item, .grid-4 .grid-sizer,
  .grid-5 .grid-item, .grid-5 .grid-sizer {
    width: 50%;
  }
  .grid-item.width-20,
  .grid-item.width-25,
  .grid-item.width-30,
  .grid-item.width-35,
  .grid-item.width-40,
  .grid-item.width-50,
  .grid-item.width-60,
  .grid-item.width-66,
  .grid-item.width-70,
  .grid-item.width-75,
  .grid-item.width-80 {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .grid-2 .grid-item, .grid-2 .grid-sizer,
  .grid-3 .grid-item, .grid-3 .grid-sizer,
  .grid-4 .grid-item, .grid-4 .grid-sizer,
  .grid-5 .grid-item, .grid-5 .grid-sizer {
    width: 100%;
  }
  .grid-item.width-20,
  .grid-item.width-25,
  .grid-item.width-30,
  .grid-item.width-35,
  .grid-item.width-40,
  .grid-item.width-50,
  .grid-item.width-60,
  .grid-item.width-66,
  .grid-item.width-70,
  .grid-item.width-75,
  .grid-item.width-80 {
    width: 100%;
  }
}

.relatedshows{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


/*----------- TEAM DETAILS ----------- */  
.team-details{
  padding-top: 250px;
}

body.bg-dark.bg-white .team-details .team-member-information .name, body.bg-white .team-details .team-member-information .name {
  color: #1b1b1b !important;
}

body.bg-dark.bg-white .team-details .team-member-information h6.widget-title, body.bg-dark.bg-white .team-details .team-member-information .widget-title.h6, body.bg-white .team-details .team-member-information h6.widget-title, body.bg-white .team-details .team-member-information .widget-title.h6 {
  color: #1b1b1b;
}

body.bg-dark.bg-white .team-details .team-member-information ul.point_order li, body.bg-white .team-details .team-member-information ul.point_order li {
  color: #1b1b1b;
}

.team-member-img img {
  max-width: 80%;
}

.team-member-information .name {
  font-size: calc(1.425rem + 1.8vw);
  font-weight: 400;
  line-height: calc(1.4875rem + 2.44286vw);
  margin-top: 0;
}

@media (min-width: 1400px) {
  .team-member-information .name {
    font-size: 3rem;
  }
}

@media (min-width: 1400px) {
  .team-member-information .name {
    line-height: 3.625rem;
  }
}

.team-member-information .designation {
  font-size: calc(1.2625rem + 0.12857vw);
  font-weight: 600;
  line-height: calc(1.4rem + 1.54286vw);
  margin-top: 0;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  position: relative;
}

@media (min-width: 1400px) {
  .team-member-information .designation {
    font-size: 1.375rem;
  }
}

@media (min-width: 1400px) {
  .team-member-information .designation {
    line-height: 2.75rem;
  }
}

/* Yellow Line left to the 'Occuopation' */
/* .team-member-information .designation:after {
  position: absolute;
  content: "";
  background: #FBEB7E;
  width: 6rem;
  height: 0.15rem;
  left: calc(100% + 15px);
  top: 50%;
  transform: translateY(-50%);
} */

.team-member-information .bio {
  margin-top: 0.9375rem;
}

.team-member-information .social-media {
  margin-top: 1.875rem;
  margin-bottom: 3.75rem;
}

.team-member-information .social-media ul {
  margin: 0px;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.team-member-information .social-media ul li {
  width: auto;
  background-image: none;
  margin-right: 10px;
  padding: 0px;
  font-weight: 600;
}

.team-member-information .social-media ul li a {
  display: block;
  font-size: 1.125rem;
  text-align: center;
  background: #545454;
  color: #ffffff;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-member-information .social-media ul li:last-child {
  margin-right: 0px;
}

.team-member-information h6.widget-title, .team-member-information .widget-title.h6 {
  color: #ffffff;
  margin-bottom: 0.625rem;
}

.team-member-information ul.point_order {
  padding-left: 0;
  margin-bottom: calc(1.34375rem + 0.96429vw);
  list-style-type: none;
}

@media (min-width: 1400px) {
  .team-member-information ul.point_order {
    margin-bottom: 2.1875rem;
  }
}

.team-member-information ul.point_order li {
  position: relative;
  padding-left: 35px;
  line-height: 36px;
  font-size: 1rem;
  color: #ffffff;
}

.team-member-information ul.point_order li span {
  color: #FBEB7E;
}

.team-member-information ul.point_order li:first-letter {
  text-transform: capitalize;
}

.team-member-information ul.point_order li:before {
  content: '\F26F';
  font-family: 'bootstrap-icons';
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  color: #FBEB7E;
}


/*----------- GALLERY - COMEDIA ----------- */  

#gallery {
  display: flex;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#gallery .thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.grid-item img#gallery_image{
  width: 100%;
}

.grid-item img.item_image {
  width: 100%;
  transition: all 0.5s ease-in-out;
  transition: transform 1s ease-in-out; 
}

.grid-item:hover img.item_image {
  /* transform: scale(1.1);
  filter: brightness(0.6) contrast(1.1) saturate(1.2) blur(3px); */
  transform: scale(1.1);
  filter: brightness(0.8) contrast(1.1) saturate(1.2);
}

.grid-item .thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

/* Causa problemas en members en el Home */
/* .thumb .item_image{
  width: 50% !important;
} */

.packery .portfolio-filters-content {
  margin-bottom: 2.5rem;
}

.project-grid-list .grid-item img.item_image {
  width: calc(100% - 150px);
}

.project-grid-list .grid-item:nth-child(even) img.item_image {
  float: right;
}

.project-grid-list .grid-item:hover img.item_image {
  /* transform: scale(1.1);
  filter: brightness(0.6) contrast(1.1) saturate(1.2) blur(3px); */
  transform: scale(1.1);
  filter: brightness(0.8) contrast(1.1) saturate(1.2);
}

.effect-tilt .grid-item .thumb {
  transform-style: preserve-3d;
}

.packery .section-header p {
  font-size: 1.8rem;
}

.packery .section-header h6 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 2rem;
}

/*-------- Filter Button ---------*/
.portfolio-filters-content {
  position: relative;
  text-align: center;
  margin-bottom: -30px;
  z-index: 1;
}

.portfolio-filters-content .filters-button-group {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  border-bottom: 0.0625rem solid #54595f;
}

.portfolio-filters-content .filters-button-group .button {
  position: relative;
  background: transparent;
  border: 0px;
  font-family: 'Quicksand', sans-serif;
  font-size: 1rem;
  line-height: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  border-bottom: 0.1875rem solid transparent;
  padding: 0rem 1.25rem 1.25rem 1.25rem;
  margin-bottom: -2px;
  cursor: pointer;
  outline: none;
  transition: all .5s ease-in-out;
}

.portfolio-filters-content .filters-button-group .button.is-checked,
.portfolio-filters-content .filters-button-group .button:hover {
  color: #FBEB7E;
  outline: none;
  border-color: #FBEB7E;
}

.portfolio-filters-content .filters-button-group .button .filter-count {
  position: absolute;
  top: 0rem;
  right: 0.5rem;
  font-size: 0.75rem;
  color: #ffffff;
  transition: all .5s ease-in-out;
}

.portfolio-filters-content .filters-button-group .button .filter-count {
  opacity: 0;
  visibility: hidden;
}

.portfolio-filters-content .filters-button-group .button:first-child.is-checked .filter-count {
  opacity: 0;
  visibility: hidden;
}

.portfolio-filters-content .filters-button-group .button.is-checked .filter-count {
  opacity: 1;
  visibility: visible;
}


/*-------- OFFER ---------*/
#sidebar_month{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sidebar_offer {
  position: sticky;
  /* top: 100px; */
  background-color: #262626;
  padding-top: 20px;
  padding-bottom: 20px;
}

body.bg-dark.bg-white .sidebar_offer, body.bg-white .sidebar_offer {
  background-color: #f8f8f8;
}

body.bg-dark.bg-white .sidebar_offer a, body.bg-white .sidebar_offer a {
  color: #000000;
}

body.bg-dark.bg-white .sidebar_offer a:hover, body.bg-white .sidebar_offer a:hover {
  color: #FBEB7E;
}

body.bg-dark.bg-white .sidebar_offer .widget ul li, body.bg-dark.bg-white .sidebar_offer .widget ol li, body.bg-white .sidebar_offer .widget ul li, body.bg-white .sidebar_offer .widget ol li {
  border-bottom-color: #e6e6e6;
}

body.bg-dark.bg-white .sidebar_offer .widget_search .wp-block-search .wp-block-search__input, body.bg-white .sidebar_offer .widget_search .wp-block-search .wp-block-search__input {
  border-color: #e6e6e6;
}

#sidebar_offer a.active {
  color: #a6a182;
}

#event_offer{
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;

}

#button-offer-month {
  position: relative;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: white;
  /* text-decoration: none; */
  text-underline-offset: 4px;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 40px;
}

#button-offer-month::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  width:120%;
  height: 2px; 
  border-bottom: 1.8px dotted #999999; 
}

#button-offer-month:hover,
#button-offer-month:active {
  color: #FBEB7E; /* Hover and active color */
}

/* .post-header {
  font-size: 3rem;
  font-weight: 800;
  line-height: 2.25rem;
  text-align: left;
  margin: 0rem 0rem 1.25rem 0rem;
  color: #ffffff;
} */

.post-info{
  font-size: 1.7rem;
  font-weight: 500;
  text-align: center;
}

.post-info-sub{
  text-align: center;

}

.post-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.25rem;
  text-align: center;
  color: #ffffff;
}

.title-offer{
  font-weight: 700;
}

.post_content{
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}

.offer_image{
  margin-bottom: 2.5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  vertical-align: middle;
  justify-content: center;
}


.post_content ul.point_order {
  padding-left: 0;
  margin-bottom: calc(1.34375rem + 0.96429vw);
  list-style-type: none;
}

@media (min-width: 1400px) {
  .post_content ul.point_order {
    margin-bottom: 2.1875rem;
  }
}

.post_content ul.point_order li {
  position: relative;
  padding-left: 35px;
  line-height: 36px;
  font-size: 1rem;
  color: #ffffff;
}

.post_content ul.point_order li span {
  color: #FBEB7E;
}

.post_content ul.point_order li:first-letter {
  text-transform: capitalize;
}

.post_content ul.point_order li:before {
  content: '\F26F';
  font-family: 'bootstrap-icons';
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  color: #FBEB7E;
}

